export function downloadHTML(htmlString, fileName) {
  const hiddenElement = document.createElement("a");
  hiddenElement.href = `data:text/html;charset=utf-8,${encodeURIComponent(
    htmlString
  )}`;
  hiddenElement.target = "_blank";
  hiddenElement.download = `${fileName}.html`;
  hiddenElement.style.display = "none";
  hiddenElement.click();
}
