import { mapActions, mapGetters } from "vuex";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import dialogTypes from "@/components/Dialogs/dialogTypes";
import dialogSize from "@/lib/calendesk-js-library/components/dialogs/dialogSize";
import planActions from "@/calendesk/mixins/planActions";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import { downloadFile } from "@/lib/calendesk-js-library/tools/downloadFile";
import store from "@/store";
import sharedListActions from "@/calendesk/mixins/sharedListActions";

export default {
  mixins: [planActions, sharedListActions],
  data() {
    return {
      isLoading: false,
      isFetchingFile: false,
      isRemovingRecordsFile: false,
    };
  },
  computed: {
    ...mapGetters({
      getRefreshNewsletterStatus: "newsletter/getRefreshNewsletterStatus",
    }),
  },
  methods: {
    ...mapActions({
      fetchStats: "dashboard/fetchStats",
      create: "newsletter/create",
      deleteMultiple: "newsletter/deleteMultiple",
      update: "newsletter/update",
      refreshNewsletter: "newsletter/refreshNewsletter",
      fetchFile: "newsletter/fetchFile",
    }),
    handleDownloadFile(selected, target, type) {
      pushEvent("downloadFile_" + type + "_" + "newsletter");

      this.isFetchingFile = true;
      this.fetchFile({
        newsletter_ids: selected.map((obj) => obj.id),
        type: type,
      })
        .then((file) => {
          downloadFile(
            file,
            type,
            this.$trans("newsletter").toLowerCase(),
            true
          );
        })
        .catch((error) => {
          errorNotification(null, error);
        })
        .finally(() => {
          this.isFetchingFile = false;
        });
    },
    handleRemoveMultiple(newsletters) {
      pushEvent("removeNewsletterRecords");

      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: () => {
          this.isRemovingRecordsFile = true;
          this.setCommonDialogLoader(true);
          this.deleteMultiple({
            newsletter_ids: newsletters.map((newsletter) => newsletter.id),
          })
            .then(() => {
              successNotification("newsletter_delete_success");
              store.dispatch("cache/clearCache", "newsletter/fetchAll");
              this.refreshNewsletter();
            })
            .catch((error) => {
              errorNotification(null, error);
            })
            .finally(() => {
              this.isRemovingRecordsFile = false;
              this.setCommonDialogLoader(false);
              this.closeConfirmDialog();
            });
        },
      });
    },
    handleAddNewsletterClick() {
      if (this.canUseNewsletter) {
        this.openDialog({
          type: dialogTypes.NEWSLETTER_CREATE_NEW_RECORD,
          title: this.$trans("add_newsletter_record"),
          size: dialogSize.SMALL,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    addEmailToNewsletterAction() {
      if (this.canUseNewsletter) {
        this.openDialog({
          type: dialogTypes.NEWSLETTER_CREATE_NEW_RECORD,
          title: this.$trans("add_newsletter_record"),
          size: dialogSize.SMALL,
          ctaButtonAction: "handleSave",
          ctaButtonIcon: "$plus-circle",
          ctaButtonTitle: this.$trans("add"),
        });
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
    sendCustomerNotification(selectedUsers) {
      if (this.canUseNewsletter) {
        this.sendNotificationsToUsers(
          selectedUsers,
          this.$helpers.customerNotificationCustomerType.newsletter,
          this.$trans("send_customers_notification_title")
        );
      } else {
        this.$root.$emit("openFeatureNotAvailableDialog");
      }
    },
  },
};
