<template>
  <v-form ref="form" @submit.prevent="handleSave">
    <v-container fluid>
      <v-row>
        <v-col v-if="!isValid" cols="12">
          <calendesk-information-message>
            {{ $trans("newsletter_new_record_info") }}
          </calendesk-information-message>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="email"
            hide-details="auto"
            :rules="[rules.required, rules.maxChars(60), rules.email]"
            :label="$trans('email')"
            outlined
          />
          <v-checkbox
            v-model="isValid"
            hide-details
            :label="$trans('email_is_valid')"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {
  email,
  maxChars,
  required,
} from "@/lib/calendesk-js-library/forms/validators";
import newsletterActions from "@/calendesk/mixins/newsletterActions";
import { errorNotification } from "@/lib/calendesk-js-library/tools/notification";
import { pushEvent } from "@/lib/calendesk-js-library/tools/helpers";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";

export default {
  name: "AddNewsletterRecordDialog",
  components: { CalendeskInformationMessage },
  mixins: [newsletterActions],
  data() {
    return {
      isLoading: false,
      email: null,
      isValid: false,
      rules: {
        required,
        maxChars,
        email,
      },
    };
  },
  methods: {
    handleSave() {
      if (this.$refs.form.validate()) {
        this.add();
      }
    },
    async add() {
      pushEvent("createNewsletterRecord");

      this.isLoading = true;
      this.$emit("ctaButtonLoading", true);

      this.$emit("loading", true);
      try {
        await this.create({
          email: this.email,
          is_valid: this.isValid ? "1" : "0",
        });
        this.fetchStats();
        this.refreshNewsletter();
        this.$emit("ctaButtonLoading", false);
        this.$emit("close");
      } catch (error) {
        if (this.$helpers.emailIsTakenError(error)) {
          errorNotification("email_is_taken", error, false);
        } else {
          errorNotification(null, error);
        }
      } finally {
        this.isLoading = false;
        this.$emit("ctaButtonLoading", false);
        this.$emit("loading", false);
      }
    },
  },
};
</script>
