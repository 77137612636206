import { downloadCSV } from "@/lib/calendesk-js-library/tools/downloadCSV";
import { downloadXlsx } from "@/lib/calendesk-js-library/tools/downloadXlsx";
import moment from "@/lib/calendesk-js-library/plugins/moment";
import helpers from "@/lib/calendesk-js-library/tools/helpers";
import { downloadHTML } from "@/lib/calendesk-js-library/tools/downloadHTML";
import { downloadVCardFile } from "@/lib/calendesk-js-library/tools/downloadVCardFile";

export function downloadFile(fileData, type, fileName, withDate) {
  if (withDate) {
    fileName = fileName + "_" + moment().format(helpers.dateFormat);
  }

  switch (type) {
    case "csv":
      downloadCSV(fileData, fileName);
      break;
    case "vcf":
      downloadVCardFile(fileData, fileName);
      break;
    case "xls":
    case "xlsx":
    case "ods":
      downloadXlsx(fileData, fileName, type);
      break;
    case "html":
      downloadHTML(fileData, fileName);
      break;
  }
}
